

import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import SchoolCampusStoreType from './indexType'
import { BodyType } from '@/Api/Message/indexType';

@Module({
    name:"SchoolCampus",
    store,
    dynamic: true,
})
export default class SchoolCampus extends VuexModule implements SchoolCampusStoreType{

    SchoolCampusList: any[] = [];
    get getSchoolCampusList(){
        return this.SchoolCampusList
    }
    @Mutation
    SetSchoolCampusList(data: any[]): void {
        this.SchoolCampusList = data
    }

    SchoolCampusUpData: BodyType = {
        pageNo: 1, pageSize: 15
    };
    get getSchoolCampusUpData(){
        return this.SchoolCampusUpData
    }
    @Mutation
    SetSchoolCampusUpData(data: BodyType): void {
        this.SchoolCampusUpData = data
    }

}

export const SchoolCampusStore = getModule( SchoolCampus )
