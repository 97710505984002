
import Storage from "@/util/Storage"
import { TheSchoolCampus } from '@/Api/Message';
import {SchoolCampusStore} from "@/views/Friend/SchoolCampus/Store";

export function getSchoolFriends() {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(TheSchoolCampus({ userId,token },SchoolCampusStore.getSchoolCampusUpData).then(res=>{
        return res.data
    }))
}
